<template>
  <div>
    <h1>404</h1>
  </div>
</template>

<script>
export default {
  name: "",
};
</script>
<style lang="less" scoped>
h1 {
  text-align: center;
  font-size: 38px;
  font-weight: 700;
}
</style>
